$(document).ready(function () {
    $(document).on('submit', '#product-question-form', function (e) {
        e.preventDefault();
        const submitButton = $('input[name="submitQuestion"]');
        submitButton.prop('disabled', true);

        $.ajax({
            url: $(this).attr('action'),
            method: 'post',
            data: $(this).serialize(),
            dataType: 'json',
            success: function (response) {
                $('.product-question__alert').remove();
                if (response.success) {
                    $('#product-question-alerts').html('<div class="alert alert-success mb-3 product-question__alert">' + response.message + '</div>');
                } else {
                    $('#product-question-alerts').html('<div class="alert alert-danger mb-3 product-question__alert">' + response.errors + '</div>');
                    setTimeout(function () {
                      submitButton.prop('disabled', false);
                    }, 500);
                }
                $('#product-question-modal').on('hidden.bs.modal', function (e) {
                    $('.product-question__alert').remove();
                    submitButton.prop('disabled', false);
                });
            }
        });
    });

    $(document).on('keydown change', '.product-question-input', function () {
      $('#product-question-alerts').html('');
    });
});
