import $ from "jquery";

export default class NewsletterPopup {
    constructor() {
        var that = this;
        $(document).ready(function () {
            if (that.checkPopupExist()) {
                that.checkSeconds();
            }
        });
        $(document).on('submit', '#form-newsletter-popup', function (e) {
            e.preventDefault();
            $.ajax({
                type: 'POST',
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',

                success: (resp) => {
                    if (resp.nw_error) {
                        $('.js-newsletter-popup-error').html(`${resp.msg}`)
                    } else {
                        $('.js-newsletter-popup-title').html(`<span class="newsletter-popup__title">${resp.msg}</span>`)
                        $('.js-form-newsletter-popup').hide();
                        $('.js-note-container').hide();
                    }
                    // $('#newsletter_popup').modal('hide');
                },
            });
        })
    }

    checkPopupExist() {
        return $('#newsletter_popup').length
    }

    checkSeconds() {
        var time = $('#popup-newsletter-email').data('seconds');
        if (time <= 0) {
            this.showPopup();
        } else {
            setTimeout(this.showPopup, (time * 1000))
        }

    }

    showPopup() {
        $('#newsletter_popup').modal('show');
        var url = $('#popup-newsletter-email').data('ajax');
        $.ajax({
            type: 'POST',
            url: url,

            success: (resp) => {
            },
        });
    }


}
