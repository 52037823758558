$(document).ready(function () {
  $(document).on('submit', '#product-request-form', function (e) {
    e.preventDefault();

    $.ajax({
      url: $(this).attr('action'),
      method: 'post',
      data: $(this).serialize(),
      dataType: 'json',
      success: function (response) {
        if(response.success) {
          $('.js-product-request-modal-body').html(response.html).wrapInner( "<div class='product-message__text pt-3'></div>");
        } else {
          alert(response.errors);
        }
      }
    });
  });
});
