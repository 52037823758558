import $ from "jquery";

export default class ProductSpinner {

    /**
     * Runs every product list initialization
     * @param container
     */
    static init(el) {
        this.initSpinner(el);
    }

    static initSpinner(el) {
        const tooltipProductWrapper = $('#tooltipProductWrapper');
        const tooltipProductEl = $('#tooltipProductEl');

        el.TouchSpin({
            buttondown_class: 'btn js-touchspin',
            buttonup_class: 'btn js-touchspin',
            min: parseInt(el.attr('min'), 10),
            max: parseInt(el.attr('max'), 10)
        });

        el.on('touchspin.on.stopspin', (spinner) => {
            prestashop.emit('updateProduct', {
                eventType: 'updatedProductQuantity',
                event: el
            });
        });

        el.on('touchspin.on.max', (spinner) => {
            tooltipProductWrapper.show();
            tooltipProductEl.tooltip('show');
            setTimeout(function () {
                tooltipProductEl.tooltip('hide');
            }, 1500);
        });

        el.on('touchspin.on.startdownspin', (spinner) => {
            tooltipProductWrapper.hide();
            tooltipProductEl.tooltip('hide');
        });
    }
}