import $ from "jquery";
import ProductList from "starter/js/components/product-list";

$(document).on('change', '.js-product-miniature-group-select', (e) => {
  const form = $(e.currentTarget).closest('form');
  const miniature = form.closest('.js-product-miniature');

  $.ajax({
    url: form.attr('action'),
    method: 'post',
    data: form.serialize(),
  }).then((response) => {
    const replacement = $(response);

    miniature.replaceWith(replacement);
    replacement.find('select.js-product-miniature-group-select').niceSelect();
    ProductList.initSpinner(replacement);
  });
});
