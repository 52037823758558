import $ from 'jquery';

export default class ListViewSwitch {
  constructor() {

    $(document).on('change', '#faceted-filter-name, #faceted-filter-reference', (ev) => {
      const value = $(ev.currentTarget).val().trim();
      const type = $(ev.currentTarget).data('type');

      localStorage.setItem(type + '-facet', value);

      prestashop.emit(
        'updateFacets',
        this.getLink(window.location.href, value, type)
      );
    });

    this.setup(window.location.href);
  }

  setup(url) {

    if (url.indexOf('Name-') >= 0) {
      this.updateRequest(url, 'Name');
    }

    if (url.indexOf('Reference-') >= 0) {
      this.updateRequest(url, 'Reference');
    }

  }

  onRefresh(url) {
    this.setup(url);
  }

  getLink(url, value, type) {
    const facetValue = value ? type + '-' + value : '';

    const newUrl = new URL(url);
    const queryParams = newUrl.searchParams;

    if (queryParams.has('q')) {
      let facetComponents = queryParams.get('q').split('/');

      if (facetValue) {
        let found = false;
        facetComponents = facetComponents.map((str) => {
          if (str.split('-')[0] === type) {
            found = true;
            return facetValue;
          }
          return str;
        });

        if (!found) {
          facetComponents.push(facetValue);
        }
      } else {
        facetComponents = facetComponents.filter(str => str.split('-')[0] !== type);
      }

      if (facetComponents.length) {
        queryParams.set('q', facetComponents.join('/'));
      } else {
        queryParams.delete('q');
      }
    } else if (value) {
      queryParams.set('q', facetValue);
    }

    newUrl.search = queryParams.toString();

    return newUrl.toString();
  }

  updateRequest(url, type) {
    const elem = type === 'Name' ? '#faceted-filter-name' : '#faceted-filter-reference';
    const elemActive = type === 'Name' ? '#active-filter-name' : '#active-filter-reference';
    const wrapperActiveFilters = '.js-active-filters-block';
    const value = localStorage[type + '-facet'];

    this.updateOtherLinks(elemActive, type, value);

    $(elem).val(value);
    $(elemActive).attr(
      'href',
      this.getLink(url, '', type)
    );

    $(elemActive + '-text').html(value);
    $(elemActive).show();
    $(wrapperActiveFilters).show();
  }

  updateOtherLinks(elem, type, value) {
    const $self = this;

    //attributes links
    $( "[data-search-url]" ).each(function() {
      $( this ).attr(
        'data-search-url',
        $self.getLink($( this ).attr('data-search-url'), value, type)
      );
    });

    //price slider
    $( "[data-slider-encoded-url]" ).each(function() {
      $( this ).attr(
        'data-slider-encoded-url',
        $self.getLink($( this ).attr('data-slider-encoded-url'), value, type)
      );
    });

    //active filters
    $('.js-search-link:not(.page-link):not(' + elem + ')').each(function() {
      const oldUrl = $( this ).attr('href') === '#' ? window.location.href : $( this ).attr('href');
      $( this ).attr(
        'href',
        $self.getLink(oldUrl, value, type)
      );
    });

  }
}
