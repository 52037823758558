import $ from "jquery";

export default class WishlistBridge {
    add(url) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'GET',
                url: url + '&action=wishListAdd',
                async: true,
                data: {"products": $('#add-mass-products-wrapper').length ? JSON.parse($('#add-mass-products-wrapper').attr('data-quantities')) : ''},
                dataType: 'json',
                success: function (response) {
                    resolve(response);
                },
                error: function (response) {
                    reject(response);
                }
            });
        });
    }

    remove(url) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'GET',
                url: url + '&action=wishListRemove',
                async: true,
                dataType: 'json',
                success: function (response) {
                    resolve(response);
                },
                error: function (response) {
                    reject(response);
                }
            });
        });
    }
}
