/* global $, prestashop */
import $ from "jquery";
import prestashop from "prestashop";
import SpinnerQuantityChecker from "./SpinnerQuantityChecker";

$(document).ready(function () {
  $(document).on('click', '.js-mass-add-submit', function (e) {
        e.preventDefault();

        var div = $('.mass-add');

        $.ajax({
            url: div.data('action'),
            method: 'post',
            data: div.closest('form').serialize(),
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    prestashop.emit('updateCart', {
                        reason: {
                            cart: prestashop.cart,
                            idProduct: response.id_product,
                            idProductAttribute: response.id_product_attribute,
                            linkAction: 'add-to-cart',
                        }
                    });
                } else {
                    alert(div.data('error-text'));
                }
            }
        });
    });

    $(document).on('click', '.js-mass-add-from-category-page', function (e) {
        e.preventDefault();

        var div = $('#add-mass-products-wrapper');

        var data = div.data('quantities');

        $.ajax({
            url: div.data('action'),
            method: 'post',
            data: {"quantities": JSON.parse(div.attr('data-quantities'))},
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    var addedProducts = '';
                    $(response.products).each(function (index) {
                        addedProducts += '<li>' + response.products[index].name + '<span>' + response.products[index].quantity_added + '</span></li>';
                    });
                    $('#add-mass-products-modal-items').html(addedProducts);
                    $('#add-mass-products-modal').modal('show').on('hide.bs.modal', (event) => {
                        $('.spinner__input').each((index, input) => {
                            var $input = $(input);
                            $input.val(0);
                            // $input.trigger("touchspin.startdownspin");
                        });
                        $('.js-add-mass-products-sum').html('0,00');
                        $('.js-product-miniature .spinner').attr('data-product-quantity', '0').attr('data-total-price', '0');
                    });
                    prestashop.emit('updateCart', {
                        reason: ''
                    });
                } else {
                    alert(div.data('error-text'));
                }
            }
        });
    });
  const spinnerQuantityChecker = new SpinnerQuantityChecker();
  prestashop.on('updateCart', () => {
    spinnerQuantityChecker.sendRequest();
  });
});
