import Wishlist from './Wishlist';
import prestashop from 'prestashop';
import $ from "jquery";
import ProductList from "starter/js/components/product-list";

$(document).ready(function () {
    const wishlist = new Wishlist();
    prestashop.wishlist = wishlist;

    $(document).on('click', '.wishlist-add', function (e) {
        e.preventDefault();
        var $el = $(this);
        wishlist
            .add($el.attr('href'))
            .then((response) => {
                let $modalAfterAddToWishlist = $('.js-add-mass-products-wishlist-modal');
                let $modalAfterAddToWishlistText = $('.js-add-mass-products-wishlist-modal-text');

                if ($('#add-mass-products-wrapper').length && $modalAfterAddToWishlist.length){
                    $modalAfterAddToWishlistText.html(response.message);
                    $modalAfterAddToWishlist.modal('show');
                }else{
                    $el.removeClass('wishlist-add');
                    $el.addClass('wishlist-remove');
                }
            });
    });

    $(document).on('click', '.wishlist-remove', function (e) {
        e.preventDefault();
        var $el = $(this);
        wishlist
            .remove($el.attr('href'))
            .then((response) => {
                // let $modalAfterAddToWishlist = $('.js-add-mass-products-wishlist-modal');
                // let $modalAfterAddToWishlistText = $('.js-add-mass-products-wishlist-modal-text');

                $el.removeClass('wishlist-remove');
                $el.addClass('wishlist-add');
                // if ($('#add-mass-products-wrapper').length && $modalAfterAddToWishlist.length){
                //     $modalAfterAddToWishlistText.html(response.message);
                //     $modalAfterAddToWishlist.modal('show');
                // }
            });
    });

    $(document).on('click', '.js-wishlist-remove-with-miniature', function (e) {
        e.preventDefault();
        const $el = $(this);
        wishlist
            .remove($el.attr('href'))
            .then((response) => {
                $el.closest('.product-miniature').remove();
            });
    });

    $(document).on('click', '.js-wishlist-remove-with-table-tr', function (e) {
        bulkActions($(this), e, [0]);
    });

    wishlist.on('afterRequest', (response) => {

    });

    $(document).on('click', '.js-wishlist-bulk-action', function(e){
        bulkActions($(this), e)
    });

    $(document).on('click', '.js-wishlist-check-all-checkboxes', checkAllCheckboxes);

    $(document).on('click','.js-wishlist-filter-sort-by', function (e){
        e.preventDefault();
        let urlsearch = $(this).attr('href');
        console.log(urlsearch);
        prestashop.emit('updateFacets', urlsearch);
    });
});

function checkAllCheckboxes(el){
    $('input.js-wishlist-checkbox:checkbox').prop('checked', this.checked);
}

function bulkActions($that, e, data = []) {
    e.preventDefault();
    let $this = $that;
    let productCheckbox = $('.js-wishlist-checkbox:checked');
    const url = $this.attr('data-url');
    let order = $('.js-wishlist-order-active').data('order-url-parameter');
    let currentPage = $('.js-wishlist-current-page').val();
    let resultsPerPage = $('#resultsPerPage').val();

    productCheckbox.each(function(index){
        let $t = $(this);
        let $product = {};
        let idProduct = parseInt($t.attr('data-id-product'));
        let idProductAttribute = parseInt($t.attr('data-id-product-attribute'));
        let quantity = parseInt($(`.js-wishlist-product-quantity-${idProduct}`).find('input[name="qty"]').val());

        $product = {
            'id_product': idProduct,
            'id_product_attribute': idProductAttribute,
            'quantity': quantity
        };
        data.push($product);
    });

  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: url,
    data: {
      products: data,
      order: order,
      page: currentPage,
      resultsPerPage: resultsPerPage,
    },
    async: true,
    success: function (response) {
      $('#js-product-list').replaceWith(response.rendered_products);
      $('#notifications').replaceWith(response.rendered_notifications);

      if (response.added_products != null){
        var addedProducts = '';
        $(response.added_products).each(function (index) {
          addedProducts += '<li>' + response.added_products[index].name + '<span>' + response.added_products[index].quantity_added + '</span></li>';
        });
        $('#add-mass-products-modal-items').html(addedProducts);
        $('#add-mass-products-modal').modal('show').on('hide.bs.modal', (event) => {
          $('.spinner__input').each((index, input) => {
            var $input = $(input);
            $input.val(0);
            // $input.trigger("touchspin.startdownspin");
          });
        });

        $.each(response.added_products, function( index, value ) {
          let qtyWrapp = $('[data-id-product="' + value.id_product +'"][data-id-product-attribute="' + value.id_product_attribute +'"]')
            .closest('.js-wishlist-table-tr').find('.js-new-quantity-in-cart');
          let qtyOld = parseInt(qtyWrapp.text());
          qtyWrapp.text(qtyOld + value.quantity_added);
        });
      }

      if (response.emit_event) {
        prestashop.emit('updateCart', {
          reason: {
            cart: prestashop.cart,
          },
        });
      }

      ProductList.initSpinner($('#content-wrapper'));
    },
    error: function (response) {
      console.log('error' + response);
    }
  });
}
