/* global $, prestashop */
$(document).ready(function () {
    prestashop.blockcart = prestashop.blockcart || {};

    var showModal = prestashop.blockcart.showModal || function (modal) {
        var $body = $('body');
        $body.append(modal);
        $body.one('click', '#blockcart-modal', function (event) {
            if (event.target.id === 'blockcart-modal') {
                $(event.target).remove();
            }
        });
    };

    $(document).ready(function () {
        prestashop.on(
            'updateCart',
            function (event) {
                var refreshURL = $('.blockcart').data('refresh-url');
                var requestData = {};

                if (typeof event.resp !== 'undefined' && typeof event.resp.hasError !== 'undefined' && event.resp.hasError) {
                    if (Array.isArray(event.resp.errors)) {
                        $('#blockcart-error-modal').modal('show').find('.modal-text').html(event.resp.errors.join("<br>"));
                    } else {
                        $('#blockcart-error-modal').modal('show').find('.modal-text').html(Object.values(event.resp.errors).join("<br>"));
                    }

                } else {
                    if (event && event.reason) {
                        requestData = {
                            id_product_attribute: event.reason.idProductAttribute,
                            id_product: event.reason.idProduct,
                            action: event.reason.linkAction,
                            actionSource: event.reason.actionSource || null,
                            quantity: (typeof event.resp !== 'undefined' ? event.resp.quantity_added : null) || null,
                        };
                    }

                    $.post(refreshURL, requestData).then(function (resp) {
                        $('.blockcart').replaceWith($(resp.preview).find('.blockcart'));
                        if (resp.modal) {
                            showModal(resp.modal);
                        }
                    }).fail(function (resp) {
                        prestashop.emit('handleError', {eventType: 'updateShoppingCart', resp: resp});
                    });
                }
            }
        );
    });

    $(document).on('change', '.js-cart-radio', function(){
        let $this = $(this);
        let url = $this.attr('data-change-cart-url');

        $('.js-cart-radio').not($this).removeAttr('checked');
        window.location.replace(url);
    });

    $('.js-cart-action-link').on('click', '.js-cart-action-link', function(){
      window.location.replace($(this).attr('data-action-cart-url'));
    });
});
