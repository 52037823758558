import $ from "jquery";
import TabChanger from "modules/nvc_wnet_accountmanagement/_dev/src/js/components/TabChanger";

$(document).ready(function () {
  if (typeof idCountryDefault !== 'undefined') {
    $(document).on('change', '#super-admin-form select[name="id_country"]', function() {
      const vatNumberInput = $('#super-admin-form input[name="vat_number"]');
      const vatNumberLabel = vatNumberInput.siblings('label');
      if ($(this).val() === idCountryDefault) {
        vatNumberInput.prop('required', true);
        vatNumberLabel.addClass('required');
      } else {
        vatNumberInput.prop('required', false)
        vatNumberLabel.removeClass('required');
      }
    });
  }

  $(document).on('submit', '#super-admin-form', function () {
    $('.register-corporation__form-submit-button').prop('disabled', true);
  });

  $(document).on('submit', '#user-form', function () {
    $('#submit-departments-form').prop('disabled', true);
  });
});

$(function() {

    $(document).on('click', '.js-form-button-fill-all', function(){
        copyInputValueToAllInputs($(this));
    });

    const registerSteps = new TabChanger();
    registerSteps.init();


    // Popup ajax save action
    // $(document).on('click', '.js-add-corporation', function(e){
    //     e.preventDefault();
    //
    //     let $this = $(this);
    //     $('#form').load($this.attr('href'));
    //
    // });
    //
    // $(document).on('submit', '#corporation-form', function(e){
    //     e.preventDefault();
    //
    //     let $this = $(this);
    //     let href = $this.attr('action');
    //     ~href.indexOf("?") ? href += '&' : href += '?';
    //     href += $this.serialize()+'&ajax=1';
    //
    //
    //     $.ajax({
    //         url : href,
    //         async: true,
    //         dataType: 'json',
    //         type: 'GET',
    //         success : function(jsonData){
    //             if (jsonData.success) {
    //                 window.location.reload();
    //             }else{
    //                 $('#form').replaceWith(jsonData.content);
    //             }
    //         },
    //     });
    // });
});

function copyInputValueToAllInputs(element) {
    let sourceInputName = element.data('source-input');
    let sourceInputValue = $(`input[name="${sourceInputName}"`).val();
    let destinyInputs = element.data('destiny-inputs');

    $.each( destinyInputs, ( key, value ) => {
        $(`input[name=${value}]`).val(sourceInputValue);
    });
}
