import FileUploaderManager from './components/FileUploaderManager';

/* global $, prestashop */
$(function () {
    const fileUploaderManager = new FileUploaderManager();

    $(document).on('submit', '#csv-add-to-cart-form', function (e) {
        e.preventDefault();
        const submitButton = $('button[name="submitCsv"]');
        submitButton.prop('disabled', true);

        let $form = $(this);
        $.ajax({
            url: $form.attr('action'),
            method: 'post',
            data: new FormData($form[0]),
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (response) {
                if (response.success) {
                    $('#csv-modal-alert').addClass('alert-success').html(response.message).show();
                    prestashop.emit('updateCart', {
                        reason: {
                            cart: prestashop.cart,
                        }
                    });
                } else {
                    $('#csv-modal-alert').addClass('alert-danger').html(response.error).show();
                    setTimeout(function () {
                      submitButton.prop('disabled', false);
                    }, 500);
                }
                $('#csv-add-to-cart-modal').on('hidden.bs.modal', function (e) {
                    $('#csv-modal-alert').hide();
                });
            }
        });
    });
});
