if (typeof get_popup_url !== 'undefined') {
  $( document ).ready(function() {
     getData();

    function getData() {
      $.ajax({
        type: 'POST',
        url: get_popup_url,
        dataType: 'json',
        success: function(jsonData)
        {
          if(jsonData.show == false){
            setTimeout(getData, jsonData.nextAjax * 1000);
          } else {
            show(jsonData.carts_tpl);
          }

        }
      });
    }

    function show(carts_tpl) {
      $('#carts-delivery-popup').html(carts_tpl);
      $('#delivery_popup').modal('show');
    }

    $(document).on('click', '.js-close-delivery-popup', function(){
      close();
    });

    function close() {
      $.ajax({
        type: 'POST',
        url: set_cookie_popup,
        dataType: 'json',
        success: function(jsonData)
        {

        }
      })
    }


  });
}
