import 'bootstrap/js/src/util';
import Form from "starter/js/components/form";
import $ from "jquery";

export default class TabChanger {
    init() {
        let that = this;
        let stepOneLink = 'js-super-admin-step-1-button';
        let stepOneLinkClickableClass = '--clickable';
        let stepTwoLink = 'js-super-admin-step-2-button';
        let formDataCompany = 'register-corporation__form-data-company';
        let formDataCompanyHandler = $(`.${formDataCompany}`);
        let formDataUser = 'register-corporation__form-data-user';
        let formDataUserHandler = $(`.${formDataUser}`);
        let hideClass = '--hidden';
        let stepHeaders = 'register-corporation__header-state-item';
        let stepOneHeader = 'register-corporation__header-state-item--step-1';
        let stepTwoHeader = 'register-corporation__header-state-item--step-2';
        let stepHeadersActiveClass = '--active';
        const form = new Form();

        $(document).ready(function () {
          if (formDataCompanyHandler.hasClass(`${formDataCompany}${hideClass}`)) {
            $(`.${stepHeaders}`)
              .removeClass(`${stepHeaders}${stepHeadersActiveClass}`)
            ;
            $(`.${stepTwoHeader}`)
              .addClass(`${stepHeaders}${stepHeadersActiveClass}`)
              .removeClass(`${stepHeaders}${stepOneLinkClickableClass}`)
            ;
            $(`.${stepOneHeader}`).addClass(`${stepOneLink} ${stepHeaders}${stepOneLinkClickableClass}` );
          }
        });

        $(document).on('click', `.${stepTwoLink}:not(.${stepHeaders}${stepHeadersActiveClass})`, function() {
            if(that.formDataCompanyValidation()){
                formDataCompanyHandler.addClass(`${formDataCompany}${hideClass}`);
                formDataUserHandler.removeClass(`${formDataUser}${hideClass}`);
                $(`.${stepHeaders}`)
                    .removeClass(`${stepHeaders}${stepHeadersActiveClass}`)
                ;
                $(`.${stepTwoHeader}`)
                    .addClass(`${stepHeaders}${stepHeadersActiveClass}`)
                    .removeClass(`${stepHeaders}${stepOneLinkClickableClass}`)
                ;
                $(`.${stepOneHeader}`).addClass(`${stepOneLink} ${stepHeaders}${stepOneLinkClickableClass}` );
            }
        });

        $(document).on('click', `.${stepOneLink}:not(.${stepHeaders}${stepHeadersActiveClass})`, function() {
            formDataUserHandler.addClass(`${formDataUser}${hideClass}`);
            formDataCompanyHandler.removeClass(`${formDataCompany}${hideClass}`);
            $(`.${stepHeaders}`)
                .removeClass(`${stepHeaders}${stepHeadersActiveClass}`)
            ;
            $(`.${stepOneHeader}`)
                .addClass(`${stepHeaders}${stepHeadersActiveClass}`)
                .removeClass(`${stepHeaders}${stepOneLinkClickableClass}`)
            ;
            $(`.${stepTwoHeader}`).addClass(`${stepTwoLink} ${stepHeaders}${stepOneLinkClickableClass}`);
        });
    }

    formDataCompanyValidation() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        let form = $('.register-corporation__form-data-company');

        var checkValidityStatus = true;
        $('input, select, textarea', form).each(function (index) {
            let item = $(this);
            if(item[0].checkValidity() === false){
                checkValidityStatus = false;
            }
        });

        if (form.length > 0) {
            // Loop over them and prevent submission
            let divToScroll = false;
            var $return = false;

            if (!checkValidityStatus) {
                $('input:invalid,select:invalid,textarea:invalid', form).each(function (index) {
                    var _field = $(this),
                        _parent = _field.closest('.form-group');
                    $('.js-invalid-feedback-browser', _parent).text(_field[0].validationMessage);
                    if (!divToScroll) {
                        divToScroll = _parent;
                    }
                });
            } else {
                $return = true;
            }
            form.addClass('was-validated');
            if (divToScroll) {
                $('html, body').animate({scrollTop: divToScroll.offset().top}, 300);
                divToScroll = false;
            }

        }
        return $return;
    }
}
