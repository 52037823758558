import TimeCounter from './time-counter';

const initShippingDateTimer = () => {
  $('.shipping-date__value').each((index, item) => {
    if ($(item).data('enabled')){
      const t = new Date();
      t.setSeconds(t.getSeconds() + $(item).data('seconds-to-check'));
      const timeCounter = new TimeCounter(t, 1000);

      timeCounter.on('change', (data) => {
        $(item).find('.shipping-date__number--hours').html(data.hours);
        $(item).find('.shipping-date__number--minutes').html(data.filled.minutes);
        $(item).find('.shipping-date__number--seconds').html(data.filled.seconds);
      });

      timeCounter.on('end', () => {});
    }
  });
}

$(() => {
  initShippingDateTimer();
});
