$(document).ready(function () {
    $(document).on('click', '#get-cvs-products', function (e) {
        $('body').append('<div class="faceted-overlay"><div class="overlay__inner"><div class="overlay__content"><span class="spinner"></span></div></div></div>');
        if (document.location.href.indexOf('?') > -1) {
            var url = document.location.href + "&render_csv";
        } else {
            var url = document.location.href + "?render_csv";
        }
        $.ajax({
            url: url,
            method: 'GET',
            xhrFields: {
                responseType: 'blob'
            },
            success: function (data) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = 'Cennik_Fdirect.xlsx';
                document.body.append(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                $('.faceted-overlay').remove();
            }
        });
    });
    $(document).on('click', '#get-cvs-products-wishlist', function () {
        var url = $(this).data('url');
        $.ajax({
            url: url,
            method: 'GET',
            xhrFields: {
                responseType: 'blob'
            },
            success: function (data) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = 'csv_products.csv';
                document.body.append(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            }
        });
    });
});
