import $ from 'jquery';
import prestashop from 'prestashop';

export default class SpinnerQuantityChecker {

  constructor() {
    this.spinner = $(".spinner");
    this.idProducts = [];
  }

  getIdProducts() {
    var that = this;
    this.idProducts = [];
    this.spinner.each(function( i ,input){
      that.idProducts.push($(input).data("product-id"));
    });
  }

  setQuantity(productQuantity) {
    this.spinner.each(function( i ,input){
      $.each(productQuantity.quantity_available,  function (id_product, quantity){
        if ($(input).data("product-id") == id_product) {
          var inputObject = $(input).find("input");
          $(inputObject).trigger("touchspin.updatesettings", {max: quantity});
          if (quantity != 0 && $(inputObject).attr('max' ) == 0 ){
            $(input).parent().parent().find('.product-miniature-buttons__column--button').find('button.js-add-to-cart').prop( "disabled", false );
          }
          var re = /\<span\sclass\=\'\quantity'\>(?<quantity>[0-9]+)\<\/span\>/;
          if ($(input).find('.product-miniature-buttons__tooltip').find('span.js-product-quantity-tooltip-item').length) {
              var newQuantity = $(input).find('.product-miniature-buttons__tooltip').find('span.js-product-quantity-tooltip-item').data('original-title').replace(re, quantity);
          }
          $(input).find('.product-miniature-buttons__tooltip').find('span.js-product-quantity-tooltip-item').attr('data-original-title', newQuantity);
          $(inputObject).attr('max',quantity );
          if (quantity == 0){
            $(input).parent().parent().find('.product-miniature-buttons__column--button').find('button.js-add-to-cart').prop( "disabled", true );
          }
        }
      })

      $.each(productQuantity.quantity_in_cart,  function (id_product, quantity){
        if ($(input).data("product-id") == id_product) {
          $(input).closest('.product-miniature').find('.js-new-quantity-in-cart').text(quantity);
          $(input).closest('.js-wishlist-table-tr').find('.js-new-quantity-in-cart').text(quantity);
        }
      })
    })
  }

  sendRequest(){
    var that = this;
    that.getIdProducts();
    $.ajax({
      url: urlGetQuantity,
      method: 'post',
      data: {"id_products": that.idProducts},
      dataType: 'json',
      success: function (response) {
        that.setQuantity(response);
      }
    })
  }

  checkQuantity(){
    this.sendRequest();
  }




}
