import $ from 'jquery';

export default class ListViewSwitch {
    constructor() {
        $(document).on('click', '.js-list-view__switch', (ev) => {
            ev.preventDefault();
            let listView = $(ev.currentTarget);
            localStorage.setItem('pr_view_type', listView.attr('data-list-view'));
            this.setCurrentListView(listView.attr('data-list-view'));
        });
        this.allPossibleOptions = [
            'product-list--v-grid',
            'product-list--v-list'
        ];

        if ($('body#manufacturer').length) {
            this.currentListView = 'product-list--v-list';
        } else {
            this.currentListView = localStorage['pr_view_type'] && $('.js-list-view__switch').length ? localStorage['pr_view_type'] : 'product-list--v-list';
        }

        this.imageSizes = {
            'product-list--v-grid': 'normal',
            'product-list--v-list': 'normal'
        };
        this.setup();
    }

    setup() {
        this.setupControls();
        this.setupSortSelect();
        let productList = ListViewSwitch.getProductList();
        this.allPossibleOptions.forEach((el) => {
            productList.removeClass( ListViewSwitch.makeClassName(el) );
        });
        productList.addClass( ListViewSwitch.makeClassName( this.currentListView ) );
        this.setupImagesSize(productList);
    }

    static makeClassName(name) {
        return name;
    }

    static getProductList() {
        return $('[id="js-product-list"] > .products');
    }

    setupImagesSize(productList) {
        productList.each((k, list) => {
            $(list).find('.js-layout-dependant-image').each((i, image) => {
                let $image = $(image);
                let size = this.imageSizes[ this.currentListView ];
                let newSRC = $image.attr(`data-src-${size}`);
                $image.attr('data-src', newSRC);
                $image.attr('src', newSRC);
            });
        })
    }

    onRefresh() {
        this.setup();
    }

    setCurrentListView(n) {
        this.currentListView = n;
        this.setup();
    }

    setupControls() {
        ListViewSwitch.getActiveControl().removeClass('list-view__switch--active');
        this.getPointedControl().addClass('list-view__switch--active');
    }

    static getActiveControl() {
        return $('.js-list-view__switch.list-view__switch--active');
    }

    getPointedControl() {
      return $(`.js-list-view__switch[data-list-view="${this.currentListView}"]`);
    }

    setupSortSelect() {
      ListViewSwitch.getActiveSortSelect().addClass('d-xl-block');
      this.getPointedSortSelect().removeClass('d-xl-block');
    }

    static getActiveSortSelect() {
      return $('.js-list-sort_switch');
    }

    getPointedSortSelect() {
      return $(`.js-list-sort_switch[data-list-view="${this.currentListView}"]`);
    }
}

