window.validateAgreements = function(e, callback, object = null) {
  e.preventDefault();

  const form = e.currentTarget.nodeName.toLowerCase() === 'form'
    ? $(e.currentTarget)
    : $(e.currentTarget).closest('form');

  const div = form.find('.wnet_agreements-form');
  const inputs = div.find('input');

  $.ajax({
    type: 'POST',
    url: div.data('action'),
    dataType: 'json',
    data: inputs.serialize(),
    success: function(response) {
      const submitButtons = form.find(':submit');

      if (!response.success) {
        inputs.each(function () {
          $(this).closest('.form-group').addClass('was-validated');
        });
        submitButtons.prop('disabled', false);
      } else if (typeof callback === 'string' && object) {
        object[callback]();
      } else if (typeof callback === 'function') {
        callback();
      } else {
        form.off('submit');

        if (submitButtons.length === 1 && !submitButtons.prop('disabled')) {
          submitButtons.click();
        } else {
          form.submit();
        }
      }
    },
  });
};

$(document).ready(function() {
  const agreementsForm = $('.wnet_agreements-form').parents('form');

  agreementsForm.on('submit', function(e) {
    if (!$(this).hasClass('js-emailsubscription-form')) {
      window.validateAgreements(e);
    }
  });
});
